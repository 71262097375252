import { createReducer } from '@reduxjs/toolkit'
import {
  getDefaultResourceState,
  setResourceFullfilled,
  setResourcePending,
  setResourceRejected,
  type Resource
} from '../../../shared/utils/store'
import type { Currency } from '../../../types/project'
import type { IUserBase } from '../../../types/user'
import type { ICountryListItem } from '../../countries/types'
import type { IProjectValues } from '../pages/changedValues/types'
import type { IProject, IProjectCard } from '../types'
import {
  addProject,
  clearAddProject,
  clearCopyProject,
  clearDeleteProject,
  clearGetCurrencies,
  clearGetMyProjects,
  clearGetProject,
  clearGetProjectAssignedUsers,
  clearGetProjectAvailableUsers,
  clearGetProjectChanges,
  clearGetProjects,
  clearSyncWithSightline,
  clearUpdateProject,
  clearUpdateProjectChanges,
  copyProject,
  deleteProject,
  getCurrencies,
  getMyProjects,
  getProject,
  getProjectAssignedUsers,
  getProjectAvailableUsers,
  getProjectChanges,
  getProjects,
  syncWithSightline,
  updateProject,
  updateProjectChanges
} from './actions'

export interface ProjectState {
  assignedProjectUsers: Resource<IUserBase[]>
  availableProjectUsers: Resource<IUserBase[]>
  addProject: Resource<IProject | null>
  currencies: Resource<Currency[] | null>
  copyProject: Resource<IProject | null>
  deleteProject: Resource<string | null>
  project: Resource<IProjectCard | null>
  projectChanges: Resource<IProjectValues[]>
  projects: Resource<IProjectCard[]>
  myProjects: Resource<IProjectCard[]>
  syncWithSightline: Resource<ICountryListItem[]>
  updateProject: Resource<IProject | null>
  updateProjectChanges: Resource<IProject | null>
}

const initialState: ProjectState = {
  assignedProjectUsers: getDefaultResourceState([]),
  availableProjectUsers: getDefaultResourceState([]),
  addProject: getDefaultResourceState(null),
  currencies: getDefaultResourceState([]),
  copyProject: getDefaultResourceState(null),
  deleteProject: getDefaultResourceState(null),
  project: getDefaultResourceState(null),
  projectChanges: getDefaultResourceState([]),
  projects: getDefaultResourceState([]),
  myProjects: getDefaultResourceState([]),
  syncWithSightline: getDefaultResourceState([]),
  updateProject: getDefaultResourceState(null),
  updateProjectChanges: getDefaultResourceState(null)
}

export default createReducer(initialState, builder =>
  builder
    .addCase(addProject.pending, state => {
      setResourcePending(state.addProject)
    })
    .addCase(addProject.rejected, (state, actions) => {
      setResourceRejected(state.addProject, actions)
    })
    .addCase(addProject.fulfilled, (state, actions) => {
      setResourceFullfilled(state.addProject)
      state.addProject.data = actions.payload
    })
    .addCase(clearAddProject, state => {
      state.addProject = initialState.addProject
    })

    .addCase(getCurrencies.pending, state => {
      setResourcePending(state.currencies)
    })
    .addCase(getCurrencies.rejected, (state, actions) => {
      setResourceRejected(state.currencies, actions)
    })
    .addCase(getCurrencies.fulfilled, (state, actions) => {
      state.currencies.data = actions.payload
      setResourceFullfilled(state.currencies)
    })
    .addCase(clearGetCurrencies, state => {
      state.currencies = initialState.currencies
    })

    .addCase(getProjects.pending, state => {
      setResourcePending(state.projects)
    })
    .addCase(getProjects.rejected, (state, actions) => {
      setResourceRejected(state.projects, actions)
    })
    .addCase(getProjects.fulfilled, (state, actions) => {
      state.projects.data = actions.payload
      setResourceFullfilled(state.projects)
    })
    .addCase(clearGetProjects, state => {
      state.projects = initialState.projects
    })

    .addCase(getMyProjects.pending, state => {
      setResourcePending(state.myProjects)
    })
    .addCase(getMyProjects.rejected, (state, actions) => {
      setResourceRejected(state.myProjects, actions)
    })
    .addCase(getMyProjects.fulfilled, (state, actions) => {
      state.myProjects.data = actions.payload
      setResourceFullfilled(state.myProjects)
    })
    .addCase(clearGetMyProjects, state => {
      state.myProjects = initialState.myProjects
    })

    .addCase(getProject.pending, state => {
      setResourcePending(state.project)
    })
    .addCase(getProject.rejected, (state, actions) => {
      setResourceRejected(state.project, actions)
    })
    .addCase(getProject.fulfilled, (state, actions) => {
      setResourceFullfilled(state.project)
      state.project.data = actions.payload
    })
    .addCase(clearGetProject, state => {
      state.project = initialState.project
    })

    .addCase(getProjectAssignedUsers.pending, state => {
      setResourcePending(state.assignedProjectUsers)
    })
    .addCase(getProjectAssignedUsers.rejected, (state, actions) => {
      setResourceRejected(state.assignedProjectUsers, actions)
    })
    .addCase(getProjectAssignedUsers.fulfilled, (state, actions) => {
      state.assignedProjectUsers.data = actions.payload
      setResourceFullfilled(state.assignedProjectUsers)
    })
    .addCase(clearGetProjectAssignedUsers, state => {
      state.assignedProjectUsers = initialState.assignedProjectUsers
    })

    .addCase(getProjectAvailableUsers.pending, state => {
      setResourcePending(state.availableProjectUsers)
    })
    .addCase(getProjectAvailableUsers.rejected, (state, actions) => {
      setResourceRejected(state.availableProjectUsers, actions)
    })
    .addCase(getProjectAvailableUsers.fulfilled, (state, actions) => {
      state.availableProjectUsers.data = actions.payload
      setResourceFullfilled(state.availableProjectUsers)
    })
    .addCase(clearGetProjectAvailableUsers, state => {
      state.availableProjectUsers = initialState.availableProjectUsers
    })

    .addCase(updateProject.pending, state => {
      setResourcePending(state.updateProject)
    })
    .addCase(updateProject.rejected, (state, actions) => {
      setResourceRejected(state.updateProject, actions)
    })
    .addCase(updateProject.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateProject)
      state.updateProject.data = actions.payload
    })
    .addCase(clearUpdateProject, state => {
      state.updateProject = initialState.updateProject
    })

    .addCase(copyProject.pending, state => {
      setResourcePending(state.copyProject)
    })
    .addCase(copyProject.rejected, (state, actions) => {
      setResourceRejected(state.copyProject, actions)
    })
    .addCase(copyProject.fulfilled, (state, actions) => {
      setResourceFullfilled(state.copyProject)
      state.copyProject.data = actions.payload
    })
    .addCase(clearCopyProject, state => {
      state.copyProject = initialState.copyProject
    })

    .addCase(deleteProject.pending, state => {
      setResourcePending(state.deleteProject)
    })
    .addCase(deleteProject.rejected, (state, actions) => {
      setResourceRejected(state.deleteProject, actions)
    })
    .addCase(deleteProject.fulfilled, (state, actions) => {
      setResourceFullfilled(state.deleteProject)
      state.deleteProject.data = actions.payload
    })
    .addCase(clearDeleteProject, state => {
      state.deleteProject = initialState.deleteProject
    })

    .addCase(getProjectChanges.pending, state => {
      setResourcePending(state.projectChanges)
    })
    .addCase(getProjectChanges.rejected, (state, actions) => {
      setResourceRejected(state.projectChanges, actions)
    })
    .addCase(getProjectChanges.fulfilled, (state, actions) => {
      setResourceFullfilled(state.projectChanges)
      state.projectChanges.data = actions.payload
    })
    .addCase(clearGetProjectChanges, state => {
      state.projectChanges = initialState.projectChanges
    })

    .addCase(updateProjectChanges.pending, state => {
      setResourcePending(state.updateProjectChanges)
    })
    .addCase(updateProjectChanges.rejected, (state, actions) => {
      setResourceRejected(state.updateProjectChanges, actions)
    })
    .addCase(updateProjectChanges.fulfilled, (state, actions) => {
      setResourceFullfilled(state.updateProjectChanges)
      state.updateProjectChanges.data = actions.payload
    })
    .addCase(clearUpdateProjectChanges, state => {
      state.updateProjectChanges = initialState.updateProjectChanges
    })

    .addCase(syncWithSightline.pending, state => {
      setResourcePending(state.syncWithSightline)
    })
    .addCase(syncWithSightline.rejected, (state, actions) => {
      setResourceRejected(state.syncWithSightline, actions)
    })
    .addCase(syncWithSightline.fulfilled, (state, actions) => {
      setResourceFullfilled(state.syncWithSightline)
      state.syncWithSightline.data = actions.payload
    })
    .addCase(clearSyncWithSightline, state => {
      state.syncWithSightline = initialState.syncWithSightline
    })
)
