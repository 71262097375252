import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { number, object, string } from 'yup'
import { FormInput } from '../../../../../../components/Form/FormInput'
import { FormSelect } from '../../../../../../components/Form/FormSelect'
import { FormTextArea } from '../../../../../../components/Form/FormTextArea'
import { RHF_SET_VALUE_OPTIONS } from '../../../../../../components/Form/utils'
import { SDKLoading } from '../../../../../../components/SDK/Loading'
import { LoadingOverlay } from '../../../../../../components/SDK/LoadingOverlay'
import { SDKModal } from '../../../../../../components/SDK/Modal'
import { SDKText } from '../../../../../../components/SDK/Text'
import { Url } from '../../../../../../constants/urls'
import { useApp } from '../../../../../../context/app.context'
import { useSubmitButton } from '../../../../../../hooks/useSubmitButton'
import { LoadingStatus } from '../../../../../../shared/types/enums'
import { useAppDispatch } from '../../../../../../store'
import { IOption } from '../../../../../../types/form'
import * as yearsActions from '../../../../../fiscalYearSetup/store/actions'
import * as yearsSelectors from '../../../../../fiscalYearSetup/store/selectors'
import * as actions from '../../../../store/actions'
import * as selectors from '../../../../store/selectors'
import { EProjectFields, ICopyProjectFields, ICopyProjectForm } from '../../../../types'

interface ICopyProjectModalProps {
  projectId: number
  yearId: number | null
  setProjectIdToCopy: (id: number | null) => void
  setYearIdToCopy: (id: number | null) => void
}

export const CopyProjectModal = ({
  projectId,
  yearId,
  setProjectIdToCopy,
  setYearIdToCopy
}: ICopyProjectModalProps) => {
  const { t } = useTranslation('myprojects')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [yearList, setYearList] = useState<IOption[]>([])
  const [isApproved, setIsApproved] = useState<boolean>(false)

  const defaultValues = {
    [EProjectFields.Description]: '',
    [EProjectFields.Name]: '',
    [EProjectFields.YearId]: ''
  }

  const validationSchema = object()
    .shape({
      [EProjectFields.Name]: string().required(t('projectForm.errors.name')!),
      [EProjectFields.YearId]: number().moreThan(0).required(t('projectForm.errors.year')!)
    })
    .required()

  const { handleSubmit, setValue, control, formState, getValues } = useForm<
    FieldValues,
    ICopyProjectForm
  >({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const { data, loading, error } = useSelector(selectors.copyProject)
  const { data: years, loading: yearsLoading } = useSelector(yearsSelectors.getPublishedYears)

  const { sendNotification } = useApp()

  useEffect(() => {
    dispatch(yearsActions.getPublishedYears())
  }, [])

  useEffect(() => {
    setYearList(
      years?.map(year => ({
        value: String(year.yearId),
        label: year.name
      }))
    )
  }, [years])

  useEffect(() => {
    const projectYearId = yearList.find(y => y.value == yearId?.toString())?.value
    if (!!projectYearId) {
      setValue(EProjectFields.YearId, projectYearId, RHF_SET_VALUE_OPTIONS)
    }
  }, [yearList, isApproved])

  const onSubmit = () => {
    const formValues = getValues()

    const params: ICopyProjectFields = {
      name: formValues[EProjectFields.Name],
      description: formValues[EProjectFields.Description],
      yearId: formValues[EProjectFields.YearId],
      originalProjectId: Number(projectId)
    }

    dispatch(actions.copyProject(params))
  }

  const onClose = () => {
    setProjectIdToCopy(null)
    setYearIdToCopy(null)
    dispatch(actions.clearCopyProject())
  }

  const { disabled } = useSubmitButton({ formState })

  useEffect(() => {
    if (error?.length) {
      sendNotification({
        message: error,
        status: 'error'
      })
      onClose()
    }
    if (loading === LoadingStatus.Succeeded) {
      sendNotification({
        message: t(`projectCard.copyProject.successInfo`)!,
        status: 'success'
      })
      onClose()
      const newProjectId = data?.[EProjectFields.ProjectId]
      if (newProjectId) {
        navigate(`${Url.MyProjectsPage}/${newProjectId}#CS-questions`)
      }
    }
  }, [error, loading])

  if (yearsLoading === LoadingStatus.Pending) {
    return <SDKLoading />
  }

  return (
    <SDKModal
      onCancel={onClose}
      title={t(`projectCard.copyProject.title`)!}
      submitLabel={
        isApproved ? t(`projectCard.copyProject.submit`)! : t(`projectCard.copyProject.continue`)!
      }
      onSubmit={isApproved ? handleSubmit(onSubmit) : () => setIsApproved(true)}
      withRequired={isApproved}
      disabledSubmit={isApproved && disabled}
      withCancel
      visible
    >
      {!isApproved && (
        <>
          <SDKText>{t(`projectCard.copyProject.copyActionWarning`)}</SDKText>
        </>
      )}
      {isApproved && (
        <>
          <div className='w-100'>
            {loading === LoadingStatus.Pending && <LoadingOverlay type='modal' />}
            <FormInput
              name={EProjectFields.Name}
              type='text'
              label={t('projectForm.projectInfo.projectName.label')!}
              required={true}
              className='ap-mb-spacing-5'
              control={control}
            />
          </div>
          {!yearId && (
            <div className='w-100'>
              <FormSelect
                name={EProjectFields.YearId}
                label={t('projectForm.projectInfo.fiscalYear.label')!}
                required={true}
                className='ap-mb-spacing-5'
                data={yearList}
                control={control}
                portalMode={true}
                onSelect={value => {
                  setValue(EProjectFields.YearId, value, RHF_SET_VALUE_OPTIONS)
                }}
              />
            </div>
          )}
          <div className='w-100'>
            <FormTextArea
              name={EProjectFields.Description}
              label={t('projectForm.projectInfo.description.label')}
              className='ap-mb-spacing-5'
              control={control}
            />
          </div>
        </>
      )}
    </SDKModal>
  )
}
