import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { AuthProvider, useAuth } from 'react-oidc-context'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import { ESessionStorage } from './constants/tokens'
import { Url } from './constants/urls'
import { AppContextProvider, AppMode, useApp, useURLParams } from './context/app.context'
import { useLogout } from './context/hooks/useLogout'
import { TaraFields } from './modules/TaraFields'
import { AddEditCountryFieldOverride } from './modules/TaraFields/pages/AddEditCountryFieldOverride'
import UndefinedPage from './modules/Undefined'
import { RedirectReason } from './modules/Undefined/const'
import Countries from './modules/countries'
import { AddEditCountry } from './modules/countries/components/AddEditCountry'
import CountriesSnapshot from './modules/countriesSnapshot'
import { AddEditQuestion } from './modules/countriesSnapshot/pages/AddEditQuestion'
import CountrySnapshotMFE from './modules/countrySnapshotMFE'
import { AuthenticationFailedPage } from './modules/error/AuthenticationFailedPage'
import { ExpiredTokenPage } from './modules/error/ExpiredTokenPage'
import { Error404 } from './modules/error/error404'
import FiscalYearDetails from './modules/fiscalYearDetails'
import GateQuestions from './modules/fiscalYearDetails/pages/GateQuestions'
import FiscalYearSetup from './modules/fiscalYearSetup'
import IndexPage from './modules/indexPage'
import AppLoading from './modules/loading'
import Login from './modules/login'
import MyProjects from './modules/myprojects'
import AddEditProject from './modules/myprojects/components/AddEditProject'
import { FormMode } from './modules/myprojects/components/AddEditProject/FormMode'
import ChangedValues from './modules/myprojects/pages/changedValues'
import ProjectSettings from './modules/myprojects/pages/projectSettings'
import PowerBITemplates from './modules/powerBITemplates'
import PowerBITemplatesManagement from './modules/powerBITemplates/pages/PowerBITemplatesManagement'
import Preferences from './modules/preferences'
import Project from './modules/project'
import Questionnaires from './modules/questionnaires'
import QuestionnaireCountries from './modules/questionnaires/pages/Countries'
import QuestionnaireQuestions from './modules/questionnaires/pages/Questions'
import Regions from './modules/regions'
import Search from './modules/search'
import SignOut from './modules/signOut'
import SystemManagement from './modules/systemManagement'
import { AddEditLegalDocument } from './modules/systemManagement/pages/AddEditLegalDocument'
import { AddPricingToolImport } from './modules/systemManagement/pages/AddPricingToolImport'
import { CookiePolicy } from './modules/systemManagement/pages/CookiePolicy'
import { EditEmailTemplate } from './modules/systemManagement/pages/EditEmailTemplate'
import { EmailTemplates } from './modules/systemManagement/pages/EmailTemplates'
import { LegalDocuments } from './modules/systemManagement/pages/LegalDocuments'
import { PricingToolImport } from './modules/systemManagement/pages/PricingToolImport'
import { Reports } from './modules/systemManagement/pages/Reports'
import { Updates } from './modules/updates'
import Users from './modules/users'
import AxiosErrorHandler from './services/AxiosErrorHandler'
import { signIn } from './services/requests/auth/signIn'
import { appInsights, reactPlugin } from './services/utils/applicationInsightsService'
import { store } from './store'
import './translations'
import { CookieSettings, UserRole } from './types/user'

const UNPPROTECTED_ROUTES = [
  Url.LoginPage,
  Url.IndexPage,
  Url.SignOutPage,
  Url.CookiesPolicy,
  Url.NotFound,
  Url.AuthenticationFailedPage
]

const ApplicationContent = () => {
  const auth = useAuth()
  const isTokenExpired = !!auth?.user?.expired
  const { appContext, updateProjectModeContext, updateCSModeContext, updateUserContext } = useApp()
  const navigate = useNavigate()
  const location = useLocation()
  const [isTpeSignInEndpointLoading, setIsTpeSignInEndpointLoading] = useState<boolean | null>(null)
  const [authenticationProcessInProgress, setAuthenticationProcessInProgress] = useState(false)
  const [isPageInitFinished, setIsPageInitFinished] = useState(false)
  const [isAuthenticationFailed, setIsAuthenticationFailed] = useState(false)
  const { t } = useTranslation()
  const { logout } = useLogout()
  let { projectId, mode } = useURLParams()

  const [locationHash] = useState<string>(location.hash)
  const [appInsightsLoaded, setAppInsightsLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!window.location.hash && locationHash) {
      window.location.hash = locationHash
    }
  }, [window.location.hash])

  useEffect(() => {
    if (mode) {
      sessionStorage.setItem(ESessionStorage.AppMode, mode)
    }

    const storedAppMode = sessionStorage.getItem(ESessionStorage.AppMode)
    if (storedAppMode) {
      if (appContext.isProjectMFE === null) {
        updateProjectModeContext(storedAppMode === AppMode.ProjectMFE)
      }

      if (appContext.isCountrySnapshotMFE === null) {
        updateCSModeContext(storedAppMode === AppMode.CountrySnapshotMFE)
      }
    }
  }, [])

  useEffect(() => {
    const TITLE_SEPARATOR = ' - '
    const title = `${window.location.pathname
      .trim()
      .replaceAll('-', ' ')
      .replaceAll('/', TITLE_SEPARATOR)
      .split(' ')
      .map(x => x.charAt(0).toUpperCase() + x.substring(1))
      .join(' ')}`

    document.title =
      title === TITLE_SEPARATOR ? t('header.title') : `${t('header.titleAbbr')}${title}`
  })

  const fetchRolesFromSignInEndpoint = async () => {
    setIsTpeSignInEndpointLoading(true)
    ;(async () => {
      const userResponse = await signIn()

      if (Array.isArray(userResponse?.roles)) {
        updateUserContext(userResponse)
        setIsTpeSignInEndpointLoading(false)
        const originatingUrl = auth.user?.state
        navigate(originatingUrl as string)
      } else {
        logout()
      }
    })()
  }

  // Automatically renew token when it's expiration is coming
  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent()
    })
  }, [auth.events, auth.signinSilent])

  useEffect(() => {
    if (auth.isLoading) {
      return
    }

    if (isTokenExpired) {
      setIsPageInitFinished(true)
      return
    }

    if (isAuthenticationFailed) {
      // If the authentication was detected as failed, only the AuthenticationFailedPage can reset it back to non-failed
      return
    }

    const isInProtectedRoute = !UNPPROTECTED_ROUTES.includes(location.pathname as Url)
    // Non-protected route
    if (!isInProtectedRoute && !authenticationProcessInProgress) {
      setIsPageInitFinished(true)
      return
    }

    const hasAccessToken = auth.user?.access_token != null
    const hasUserRoles = appContext.user?.roles != null

    if (!hasAccessToken && !authenticationProcessInProgress) {
      setAuthenticationProcessInProgress(true)
      updateUserContext(null)

      let signinRedirectArgs = {
        state: `${location.pathname}${locationHash}`
      }
      auth.signinRedirect(signinRedirectArgs)
      return
    }

    if (auth.error) {
      auth.removeUser()
      setIsAuthenticationFailed(true)
      setAuthenticationProcessInProgress(false)
      setIsPageInitFinished(true)
      return
    }

    if (hasAccessToken && !hasUserRoles && !isTpeSignInEndpointLoading) {
      setAuthenticationProcessInProgress(true)
      fetchRolesFromSignInEndpoint()
      return
    }

    if (hasAccessToken && hasUserRoles) {
      setAuthenticationProcessInProgress(false)
      setIsPageInitFinished(true)
    }
  }, [location, auth, appContext.user])

  useEffect(() => {
    if (appContext.isProjectMFE && projectId) {
      navigate(`${Url.MyProjectsPage}/${projectId}`)
    }
  }, [appContext.isProjectMFE])

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <AppLoading textKey='signIn.statusMessage' />
    case 'signoutRedirect':
      return <AppLoading textKey='signOut.statusMessage' />
  }

  if (
    !isPageInitFinished ||
    auth.isLoading ||
    authenticationProcessInProgress ||
    isTpeSignInEndpointLoading
  ) {
    return <AppLoading />
  }

  if (!appInsightsLoaded && appContext.user?.cookieSettings == CookieSettings.AcceptAll) {
    appInsights.loadAppInsights()
    appInsights.trackPageView({ name: document.title })
    setAppInsightsLoaded(true)
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AxiosErrorHandler>
        <>
          {isAuthenticationFailed && (
            <AuthenticationFailedPage setIsAuthenticationFailed={setIsAuthenticationFailed} />
          )}
          {!isAuthenticationFailed && isTokenExpired && <ExpiredTokenPage />}
          {!isAuthenticationFailed && !isTokenExpired && (
            <Provider store={store}>
              <DndProvider backend={HTML5Backend}>
                <Routes>
                  <Route
                    path={Url.IndexPage}
                    element={
                      auth.isAuthenticated ? <Navigate to={Url.MyProjectsPage} /> : <IndexPage />
                    }
                  />
                  <Route path={Url.LoginPage} element={<Login />} />
                  <Route path={Url.SignOutPage} element={<SignOut />} />
                  <Route path={Url.NotFound} element={<Error404 />} />
                  <Route
                    path={Url.AuthenticationFailedPage}
                    element={<AuthenticationFailedPage />}
                  />
                  <Route path={Url.CookiesPolicy} element={<CookiePolicy />} />
                  <Route
                    element={
                      <ProtectedRoute
                        authenticationProcessInProgress={authenticationProcessInProgress}
                        allowedRoles={[
                          UserRole.InternalUser,
                          UserRole.ExternalUser,
                          UserRole.CountryEditor
                        ]}
                      />
                    }
                  >
                    <Route path={Url.MyProjectsPage} element={<MyProjects />} />
                    <Route path={`${Url.MyProjectsPage}/:project`} element={<Project />} />
                    <Route
                      path={`${Url.MyProjectsPage}${Url.AddProjectPage}/:step`}
                      element={<AddEditProject mode={FormMode.Add} />}
                    />
                    <Route
                      path={`${Url.MyProjectsPage}/:project${Url.ProjectSettingsPage}`}
                      element={<ProjectSettings />}
                    />
                    <Route
                      path={`${Url.MyProjectsPage}/:project${Url.ChangedValuesPage}`}
                      element={<ChangedValues />}
                    />
                    <Route
                      path={`${Url.MyProjectsPage}/:project${Url.EditProjectPage}/:step`}
                      element={<AddEditProject mode={FormMode.Edit} />}
                    />
                    <Route path={Url.Updates} element={<Updates />} />
                    <Route path={Url.Search} element={<Search />} />
                    <Route path={`${Url.CountrySnapshotPage}`} element={<CountrySnapshotMFE />} />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        authenticationProcessInProgress={authenticationProcessInProgress}
                        allowedRoles={[UserRole.CountryEditor]}
                      />
                    }
                  >
                    <Route path={`${Url.QuestionnairesPage}`} element={<Questionnaires />} />
                    <Route
                      path={`${Url.QuestionnairesPage}/:year`}
                      element={<QuestionnaireCountries />}
                    />
                    <Route
                      path={`${Url.QuestionnairesPage}/:year/:country`}
                      element={<QuestionnaireQuestions />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoute
                        authenticationProcessInProgress={authenticationProcessInProgress}
                        allowedRoles={[UserRole.Admin]}
                      />
                    }
                  >
                    <Route path={Url.FiscalYearPage} element={<FiscalYearSetup />} />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.RegionsPage}`}
                      element={<Regions />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.TaraFieldsPage}`}
                      element={<TaraFields />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.TaraFieldsPage}`}
                      element={<TaraFields />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.TaraFieldsPage}${Url.AddTaraFieldsPage}`}
                      element={<AddEditCountryFieldOverride />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.TaraFieldsPage}${Url.EditTaraFieldsPage}/:id`}
                      element={<AddEditCountryFieldOverride />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.CountriesPage}`}
                      element={<Countries />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.CountriesPage}${Url.AddCountryPage}`}
                      element={<AddEditCountry />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.CountriesPage}${Url.EditCountryPage}/:id`}
                      element={<AddEditCountry />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.CountriesSnapshotPage}`}
                      element={<CountriesSnapshot />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.CountriesSnapshotPage}${Url.EditQuestionPage}/:id`}
                      element={<AddEditQuestion />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.CountriesSnapshotPage}${Url.AddQuestionPage}`}
                      element={<AddEditQuestion />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.GateQuestions}`}
                      element={<GateQuestions />}
                    />
                    <Route path={`${Url.FiscalYearPage}/:year`} element={<FiscalYearDetails />} />
                    <Route path={Url.PreferencesPage} element={<Preferences />} />
                    <Route path={Url.SystemManagementPage} element={<SystemManagement />} />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.EmailTemplatesPage}`}
                      element={<EmailTemplates />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.LegalDocumentsPage}`}
                      element={<LegalDocuments />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.UsersPage}`}
                      element={<Users />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.EmailTemplatesPage}${Url.EditEmailTemplatePage}/:id`}
                      element={<EditEmailTemplate />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.LegalDocumentsPage}${Url.EditLegalDocumentPage}/:id`}
                      element={<AddEditLegalDocument />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.LegalDocumentsPage}${Url.AddLegalDocumentPage}`}
                      element={<AddEditLegalDocument />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.PricingToolImportPage}${Url.AddPricingToolImport}`}
                      element={<AddPricingToolImport />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.PowerBITemplatesPage}`}
                      element={<PowerBITemplates />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.PowerBITemplatesPage}${Url.PowerBITemplatesManagementPage}`}
                      element={<PowerBITemplatesManagement />}
                    />
                    <Route
                      path={`${Url.FiscalYearPage}/:year${Url.PowerBITemplatesPage}${Url.PowerBITemplatesManagementPage}/:id`}
                      element={<PowerBITemplatesManagement />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.ReportsPage}`}
                      element={<Reports />}
                    />
                    <Route
                      path={`${Url.SystemManagementPage}${Url.PricingToolImportPage}`}
                      element={<PricingToolImport />}
                    />
                  </Route>
                  <Route path={`${Url.Server}/:response`} element={<UndefinedPage />} />
                  <Route path='*' element={<UndefinedPage reason={RedirectReason.NotFound} />} />
                </Routes>
              </DndProvider>
            </Provider>
          )}
        </>
      </AxiosErrorHandler>
    </AppInsightsContext.Provider>
  )
}

const TPEAuthProvider: React.FunctionComponent = () => {
  return (
    <AuthProvider
      authority={process.env.REACT_APP_AUTHORITY_HOST!}
      client_id={process.env.REACT_APP_CLIENT_ID!}
      redirect_uri={process.env.REACT_APP_REDIRECT_URL!}
      scope={process.env.REACT_APP_SCOPE!}
      accessTokenExpiringNotificationTimeInSeconds={Number(
        process.env.REACT_APP_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME_IN_SECONDS
      )}
    >
      <ApplicationContent />
    </AuthProvider>
  )
}

export const ApplicationWithAuthentication: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <TPEAuthProvider />
      </AppContextProvider>
    </BrowserRouter>
  )
}
