import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { CardListItem } from '../../../../components/CardListItem'
import { EAction, ECardListItem } from '../../../../components/CardListItem/const'
import { IActionCallback } from '../../../../components/CardListItem/type'
import { EmptyState } from '../../../../components/EmptyState'
import { EProjectFields, IProjectCard } from '../../types'
import { CopyProjectModal } from '../AddEditProject/components/CopyProjectModal'
import { DeleteProjectModal } from '../DeleteProjectModal'
import './index.scss'
import { setFiltersAndSorting } from './utils/setFiltersAndSorting'

export const ProjectList = ({ list }: { list: IProjectCard[] }) => {
  const [filteredList, setFilteredList] = useState<IProjectCard[]>([])
  const [projectIdToDelete, setProjectIdToDelete] = useState<number | null>(null)
  const [projectIdToCopy, setProjectIdToCopy] = useState<number | null>(null)
  const [yearIdToCopy, setYearIdToCopy] = useState<number | null>(null)

  const handleActionClick = (callback: IActionCallback) => {
    if (callback.action == EAction.Delete) {
      setProjectIdToDelete(Number(callback.id))
    } else if (callback.action == EAction.Copy) {
      setProjectIdToCopy(Number(callback.id))
      const projectYearId = list.find(p => p[EProjectFields.ProjectId] == Number(callback.id))?.[
        EProjectFields.YearId
      ]
      setYearIdToCopy(Number(projectYearId))
    }
  }

  useEffect(() => {
    if (list) {
      const filters = queryString.parse(location.search)
      const newFilteredList = setFiltersAndSorting({ list, filters })
      setFilteredList(newFilteredList)
    }
  }, [location.search, list])

  if (filteredList?.length === 0) {
    return <EmptyState />
  } else {
    return (
      <div className='ap-grid mx-0 project-list'>
        {filteredList?.map((project: IProjectCard) => (
          <div className='project-list-item g-col-12' key={project.projectId}>
            <CardListItem
              cardListItem={ECardListItem.ProjectCard}
              actions={[EAction.Copy, EAction.Delete]}
              handleClick={(callback: IActionCallback) => handleActionClick(callback)}
              {...project}
            />
          </div>
        ))}
        {projectIdToDelete !== null && (
          <DeleteProjectModal
            projectId={projectIdToDelete}
            setProjectIdToDelete={setProjectIdToDelete}
          />
        )}
        {projectIdToCopy !== null && (
          <CopyProjectModal
            projectId={projectIdToCopy!}
            yearId={yearIdToCopy}
            setProjectIdToCopy={setProjectIdToCopy}
            setYearIdToCopy={setYearIdToCopy}
          />
        )}
      </div>
    )
  }
}
