import { useEffect } from 'react'
import { SDKTab } from '..//Tab'
import type { ITabsProps } from '../Tabs'
import { SDKTabs } from '../Tabs'
import './index.scss'

export interface ITabberItem {
  label: string
  value: number
  hashUrl: string | string[]
  moduleId?: number
  isPermitted?: boolean
}

interface ITabberProps extends ITabsProps {
  tabs: ITabberItem[]
  activeIndex: number
  setActiveIndex: (number: number) => void
  additionalChange?: () => void
}

export const SDKTabber = ({
  tabs,
  activeIndex,
  setActiveIndex,
  additionalChange,
  ...props
}: ITabberProps) => {
  const hash = window.location.hash.slice(1)

  useEffect(() => {
    const selectedTab = tabs.find(tab => {
      if (Array.isArray(tab.hashUrl)) return tab.hashUrl.find(url => url === hash)
      else return tab.hashUrl === hash
    })

    if (selectedTab) {
      if (selectedTab.value === activeIndex) {
        return
      }
      setActiveIndex(selectedTab.value)
    }
  }, [activeIndex, tabs])

  const onChange = (value: string) => {
    if (value === activeIndex.toString()) {
      return
    }
    const numberValue = Number(value)
    const hashUrl = tabs[numberValue].hashUrl
    window.history.pushState(null, '', `#${Array.isArray(hashUrl) ? hashUrl[0] : hashUrl}`)
    setActiveIndex(numberValue)
    additionalChange?.()
  }

  return (
    <SDKTabs
      activeIndex={activeIndex}
      onTabChange={(_: number, v: string) => onChange(v)}
      className='tabber'
      {...props}
    >
      {tabs.map((tab, i) => (
        <SDKTab label={tab.label} value={String(tab.value)} key={`tabber-element-${i}`}></SDKTab>
      ))}
    </SDKTabs>
  )
}
